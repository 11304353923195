// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_ry g_h4 g_d9";
export var heroHeaderCenter = "s_h5 g_h5 g_d9 g_gd";
export var heroHeaderRight = "s_h6 g_h6 g_d9 g_gf";
export var heroParagraphLeft = "s_rz g_h1 g_fd";
export var heroParagraphCenter = "s_h2 g_h2 g_fd g_gd";
export var heroParagraphRight = "s_h3 g_h3 g_fd g_gf";
export var heroBtnWrapperLeft = "s_rB g_bc g_gF g_ch g_dh g_dn";
export var heroBtnWrapperCenter = "s_rC g_gG g_gF g_ch g_dh g_dl";
export var heroBtnWrapperRight = "s_rD g_gH g_gF g_ch g_dh g_dp";
export var overlayBtnWrapper = "s_rF g_h0 g_cM g_cV g_cW g_cX g_c5 g_dW";
export var design4 = "s_rG g_hZ g_cM g_cV g_cW g_c5";
export var heroExceptionSmall = "s_rH x_rH";
export var heroExceptionNormal = "s_rJ x_rJ";
export var heroExceptionLarge = "s_rK x_rK";
export var Title1Small = "s_rL x_rL x_sM x_sN";
export var Title1Normal = "s_rM x_rM x_sM x_sP";
export var Title1Large = "s_rN x_rN x_sM x_sQ";
export var BodySmall = "s_rP x_rP x_sM x_s4";
export var BodyNormal = "s_rQ x_rQ x_sM x_s5";
export var BodyLarge = "s_rR x_rR x_sM x_s6";