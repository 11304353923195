const getColor = (color, colors) => {
  let result;
  if (color && colors && colors.length === 4 && color.solid && color.solid.startsWith('COLOR')) {
    let [, index] = color.solid.split('_');
    index = Number(index);
    if (index >= 0 && index <= 3) result = colors[index];
  } else if (color && color.solid && !color.solid.startsWith('COLOR')) result = color.solid;
  return result;
};

export default getColor;
