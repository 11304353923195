import React from 'react';
import { Link } from 'gatsby';

import { constructLink } from '../../../helper';
import CustomizableButton from '../../CustomizableButton';
import getLinkTarget from '../../../getLinkTarget';

import * as styles from './styles.module.css';

class ButtonEditor extends React.Component {
  constructor(props) {
    super(props);

    let link;
    let type;
    if (props.data) {
      ({ link, type } = constructLink(
        props.data.linkObj,
        props.pagePathList,
        props.articlePathList,
        props.filePathList,
        props.categoryPathList
      ));
    }

    let label;
    if (props.text !== undefined) label = props.text;
    else if (props.data.label) ({ label } = props.data);
    else label = '';

    const { data } = props;
    if (data && data.styles && data.styles.id && props.buttons)
      data.styles = props.buttons.find((b) => data.styles.id === b.id) || data.styles;

    this.state = { type, link, data, label };
  }

  render() {
    const button = (
      <CustomizableButton
        className={this.props.styleN && styles[this.props.styleN]}
        fullWidth={this.props.fullWidth === true}
        button={this.props.button}
        themeData={this.props.themeData}
        data={this.state.data}
        invert={this.props.invert}
        label={this.state.label}
        type={this.props.type || 'submit'}
      />
    );

    const isLink = !!this.state.link;
    const isLocal = isLink && !['EXTERNAL', 'FILE'].includes(this.state.type);
    let previewTarget = '_blank';
    if (this.state.type !== 'FILE' && isLink) previewTarget = getLinkTarget(this.props.data, 'tab');

    let result = button;
    if (isLink && isLocal && previewTarget !== '_blank') {
      result = (
        <Link to={this.state.link} className={this.props.fullWidth === true ? styles.width100 : ''} draggable="false">
          {button}
        </Link>
      );
    } else if (isLink && (!isLocal || previewTarget === '_blank')) {
      result = (
        <a
          href={this.state.link}
          target={previewTarget}
          rel="noopener noreferrer"
          className={this.props.fullWidth === true ? styles.width100 : ''}
          draggable="false"
        >
          {button}
        </a>
      );
    }

    return result;
  }
}

export default ButtonEditor;
