// extracted by mini-css-extract-plugin
export var alignLeft = "z_pY g_g0 g_dn g_gc";
export var alignCenter = "z_dw g_g1 g_dl g_gd";
export var alignRight = "z_pZ g_g2 g_dp g_gf";
export var testimonialsContainer = "z_t8 g_gz";
export var testimonialsContainerFull = "z_t9 g_gx";
export var testimonialsMainHeader = "z_kW g_kW g_ch g_fd";
export var testimonialsComponentParagraph = "z_kY g_kY";
export var testimonialsComponentSmall = "z_kX g_kX";
export var testimonialsComponentsWrapper = "z_kS g_kS g_ck g_ch g_fc g_dM g_dP";
export var testimonialsComponentsWrapperDesign2 = "z_kT g_kT g_ck g_ch g_fc g_dM g_dP g_dk";
export var testimonialsComponentsWrapperDesign3 = "z_kV g_kV g_ck g_ch g_cs g_dP g_dB g_dh g_dq g_dw g_dl";
export var testimonialsBackgroundColor = "z_vb g_gL";
export var colEqualHeight = "z_vc g_dh";
export var testimonialsColumnWrapper = "z_kZ g_kZ g_dM";
export var testimonialsImageWrapper = "z_kP g_kP g_ch g_cL";
export var testimonialsImageWrapperDesign2 = "z_kQ g_kQ g_cc g_cL g_df";
export var testimonialsImageWrapperDesign3 = "z_kR g_kR g_cL g_dh";
export var design2TextWrapper = "z_vd g_dk g_dw g_dq";
export var design3 = "z_vf g_fl g_cs";
export var imageDesign2 = "z_vg";
export var SmallSmall = "z_ts x_ts x_sM x_s7";
export var SmallNormal = "z_tt x_tt x_sM x_s8";
export var SmallLarge = "z_tv x_tv x_sM x_s5";
export var exceptionWeight = "z_rp x_tc";