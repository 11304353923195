// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "r_lc g_lc g_fc";
export var galleryMasonryImage = "r_W g_W g_ch g_dy g_gj";
export var alignLeft = "r_pY g_g0 g_dn g_gc";
export var alignCenter = "r_dw g_g1 g_dl g_gd";
export var alignRight = "r_pZ g_g2 g_dp g_gf";
export var galleryContainer = "r_rq g_gz";
export var galleryContainerFull = "r_rr g_gx";
export var galleryRowWrapper = "r_rs g_dW";
export var galleryGuttersImage = "r_lf g_lf g_cv g_fc";
export var galleryNoGuttersImage = "r_ld g_ld g_cv g_fl";
export var galleryTextGutters = "r_k9 g_k9 g_fd";
export var galleryTextNoGutters = "r_lb g_lb g_fd";
export var galleryTextMasonry = "r_rt g_k9 g_fd";
export var galleryImageWrapper = "r_rv g_gT g_cL";
export var descText = "r_rw g_lg g_cM g_cX g_cV g_cW g_b8";
export var guttersDesc = "r_rx";