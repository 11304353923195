// extracted by mini-css-extract-plugin
export var lbContainerOuter = "k_pJ";
export var lbContainerInner = "k_pK";
export var movingForwards = "k_pL";
export var movingForwardsOther = "k_pM";
export var movingBackwards = "k_pN";
export var movingBackwardsOther = "k_pP";
export var lbImage = "k_pQ";
export var lbOtherImage = "k_pR";
export var prevButton = "k_pS";
export var nextButton = "k_pT";
export var closing = "k_pV";
export var appear = "k_pW";