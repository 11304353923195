const messages = {
  en: {
    errorHappenedCheckSecuritySettings:
      "It looks like your browser's settings are too strict to display this content.\n\nPlease check your browser’s Privacy & Security settings, switch mode to “Standard” and try again.",
  },
  fi: {
    errorHappenedCheckSecuritySettings:
      'Näyttää siltä, että selaimesi asetukset ovat liian tiukat tämän sisällön näyttämiseen.\n\nTarkista selaimesi asetukset Tietosuoja ja turvallisuus kohdasta, vaihda tilaksi Normaali ja yritä uudelleen.',
  },
};

const fallbackLanguage = 'en';

const getText = (translationKey, language) => {
  const languageMessages = messages[language] || messages[fallbackLanguage];
  const message = languageMessages[translationKey] || messages[fallbackLanguage][translationKey];

  return message || translationKey;
};

export default getText;
