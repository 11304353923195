const getLinkTarget = (data, tabName) => {
  let previewTarget;

  if (typeof data.linkObj === 'object') previewTarget = data.linkObj.openLinkInNewTab ? '_blank' : '_self';
  else previewTarget = data[tabName] ? '_blank' : '_self';

  return previewTarget;
};

export default getLinkTarget;
