import React from 'react';

import * as styles from './styles.module.css';

class SimpleRadioButton extends React.PureComponent {

  render() {

    const flex = this.props.flex === 'column' ? styles.radioInputColumn : styles.radioInputRow;

    return (
      <div
        className={`form_radio_${this.props.index}_${this.props.idx}_${this.props.sectionId} ${flex}`}
      >
        <input
          id={`radioOptionGroup_${this.props.index}_${this.props.idx}_${this.props.sectionId}`}
          type="radio"
          name={`radioOptionGroup_${this.props.index}_${this.props.sectionId}`}
          className={this.props.flex === 'column' ? styles.inputOptionColumn : styles.inputOptionRow}
          data-index={this.props.idx}
          value={this.props.value}
        />
        <label htmlFor={`radioOptionGroup_${this.props.index}_${this.props.idx}_${this.props.sectionId}`}>
          {this.props.value}
        </label>
      </div>
    );

  }

}

export default SimpleRadioButton;
