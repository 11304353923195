// extracted by mini-css-extract-plugin
export var articleWrapper = "f_bn";
export var articleText = "f_bp g_gc g_d9";
export var header = "f_bq g_cL g_ch";
export var headerImageWrapper = "f_br g_c0 g_cM g_cW g_cV g_cR g_cX g_c2 g_dy";
export var headerGradient = "f_bs g_c0 g_cM g_cW g_cV g_cR g_cX";
export var headerGradientOverlay = "f_bt g_c0 g_cM g_cW g_cV g_cR g_cX";
export var headerContentWrapper = "f_bv g_dh g_dw g_dl g_dq g_c3 g_cL";
export var contentWrapper = "f_bg g_ch";
export var dateTag = "f_bw g_dY g_d8 g_f7 g_ch";
export var icon1 = "f_bx g_fw";
export var icon2 = "f_by g_fw g_fq";
export var tagBtn = "f_bz g_dK g_fl g_f6 g_gj";
export var headerText = "f_bB g_ch g_dY g_d9 g_dD";
export var center = "f_bC g_gd";
export var videoIframeStyle = "f_bD g_gJ g_ch g_cs g_dg g_dK g_cC";
export var articleImageWrapper = "f_bF g_d9 g_cL";
export var articleImageWrapperIcon = "f_bG g_d9 g_cL";
export var articleRow = "f_bH g_dl g_h";
export var quoteWrapper = "f_bJ g_dk g_dw g_d9";
export var quoteBar = "f_bK g_cs";
export var quoteText = "f_bL";
export var authorBox = "f_bM g_ch";
export var authorRow = "f_bN g_dl g_dD g_fg";
export var separator = "f_bP g_ch";
export var line = "f_bQ g_ch g_bQ g_fG g_fg";
export var authorImage = "f_bR g_dR g_cL g_ch g_fb";
export var authorText = "f_bS g_d0 g_fb";
export var masonryImageWrapper = "f_bT";
export var bottomSeparator = "f_bV g_ch g_fg";
export var linksWrapper = "f_bW g_d0 g_gc";
export var comments = "f_bX g_dl g_fg";
export var sharing = "f_bY g_dh g_dl g_dq";
export var shareText = "f_bZ g_ch g_gd";
export var icon = "f_b0";
export var text = "f_b1";
export var SubtitleSmall = "f_b2";
export var SubtitleNormal = "f_b3";
export var SubtitleLarge = "f_b4";