// extracted by mini-css-extract-plugin
export var alignLeft = "p_pY g_g0 g_dn g_gc";
export var alignCenter = "p_dw g_g1 g_dl g_gd";
export var alignRight = "p_pZ g_g2 g_dp g_gf";
export var contactFormWrapper = "p_jN g_jN g_dB g_dP";
export var contactFormText = "p_qH";
export var inputFlexColumn = "p_qJ";
export var inputFlexRow = "p_qK";
export var contactForm = "p_jL g_jL g_ch g_dq";
export var contactFormHeader = "p_jP g_jP g_fH g_fM";
export var contactFormParagraph = "p_jQ g_jQ g_fC g_fM";
export var contactFormSubtitle = "p_jR g_jR g_fD g_fM";
export var contactFormLabel = "p_jM g_jM g_ch g_dg";
export var contactFormInputSmall = "p_jW g_jW g_ch g_dM g_dP";
export var contactFormInputNormal = "p_jX g_jX g_ch g_dM g_dP";
export var contactFormInputLarge = "p_jY g_jY g_ch g_dM g_dP";
export var contactFormTextareaSmall = "p_jS g_jS g_ch g_dM g_dP";
export var contactFormTextareaNormal = "p_jT g_jT g_ch g_dM g_dP";
export var contactFormTextareaLarge = "p_jV g_jV g_ch g_dM g_dP";
export var contactRequiredFields = "p_jZ g_jZ g_ch g_dh";
export var inputField = "p_qL";
export var inputOption = "p_qM";
export var inputOptionRow = "p_qN";
export var inputOptionColumn = "p_qP";
export var radioInput = "p_qQ";
export var select = "p_qm";
export var contactBtnWrapper = "p_qR g_gG g_gF g_ch g_dh g_dl";
export var shake = "p_qS";
export var sending = "p_qT";
export var blink = "p_qV";