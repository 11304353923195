const fontSizeSwitch = (size) => {
  switch (size) {
    case 'Small':
      return '0.85rem';
    case 'Normal':
      return '1rem';
    case 'Large':
      return '1.25rem';
    default:
      return '1rem';
  }
};

export default fontSizeSwitch;
