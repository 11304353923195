// extracted by mini-css-extract-plugin
export var articleWrapper = "n_bn";
export var searchResult = "n_qg n_bn";
export var line = "n_bQ g_bQ g_fG";
export var articleTitle = "n_qh g_d7";
export var articleText = "n_bp g_d9";
export var authorImage = "n_bR g_bR g_dR g_cL g_fb";
export var authorText = "n_bS g_d0 g_fb";
export var textCenter = "n_gd g_gd";
export var searchWrapper = "n_qj g_dh g_dw g_f8 g_d3 g_cL";
export var searchInput = "n_qk g_fm g_ch g_dN";
export var searchBtn = "n_ql g_dK g_dk g_dw g_cM g_fl g_gj";
export var text = "n_b1 g_d8";
export var elementWrapper = "n_bd g_d3";
export var select = "n_qm g_ch g_dN";
export var articleImageWrapper = "n_bF g_d9 g_cL g_cG";
export var articleImageWrapperIcon = "n_bG g_d9 g_cL g_cG";
export var articleImageWrapperColumns = "n_qn g_d9 g_gV g_cL";
export var contentPadding = "n_qp g_fz";
export var otherPadding = "n_qq g_ft";
export var paginationWrapper = "n_qr";
export var pagination = "n_qs";
export var disabledPagination = "n_qt";
export var breakMe = "n_qv";
export var activePage = "n_qw";
export var next = "n_qx";
export var pages = "n_qy";
export var searchTitle = "n_qz g_d8";
export var categoryTitle = "n_qB g_d8";
export var searchText = "n_qC g_fb";
export var bold = "n_qD";
export var field = "n_qF";
export var dateTag = "n_bw g_b6 g_d8 g_f7";
export var icon1 = "n_bx g_fw";
export var icon2 = "n_by g_fw g_fq";
export var tagBtn = "n_bz g_dK g_fl g_f6 g_gj";
export var linksWrapper = "n_bW g_d0 g_gd";
export var resultImage = "n_qG";