const getTag = (node, tag) => {
  const t = document.createElement(tag);

  t.innerHTML = node.innerHTML;
  if (node.attributes && node.attributes.length > 0) {
    Array.prototype.forEach.call(node.attributes, (attr) => {
      console.log(attr)
      t.setAttribute(attr.name, attr.value);
    });
  }

  return t;
};

export default getTag;
