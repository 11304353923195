// extracted by mini-css-extract-plugin
export var alignDiscLeft = "q_qW g_g0 g_dn g_gc";
export var alignLeft = "q_pY g_g0 g_dn g_gc";
export var alignDiscCenter = "q_qX g_g1 g_dl g_gd";
export var alignCenter = "q_dw g_g1 g_dl g_gd";
export var alignDiscRight = "q_qY g_g2 g_dp g_gf";
export var alignRight = "q_pZ g_g2 g_dp g_gf";
export var footerContainer = "q_qZ g_gz g_dD";
export var footerContainerFull = "q_q0 g_gx g_dD";
export var footerHeader = "q_lD g_lD";
export var footerTextWrapper = "q_q1 g_ch";
export var footerDisclaimerWrapper = "q_lK g_lK g_d2";
export var badgeWrapper = "q_q2 g_ch g_dh g_dw g_dm g_dq";
export var footerDisclaimerRight = "q_lL g_lL g_dh";
export var footerDisclaimerLeft = "q_lM g_lM g_dh";
export var verticalTop = "q_q3 g_dh g_dq g_dv g_ds";
export var firstWide = "q_q4";
export var disclaimer = "q_q5";
export var socialDisclaimer = "q_q6";
export var left = "q_q7";
export var wide = "q_q8 g_fg";
export var right = "q_q9 g_dr";
export var line = "q_bQ g_gX g_fc";
export var badgeDisclaimer = "q_rb g_dk g_dw g_dq";
export var badgeContainer = "q_rc g_dh g_dp g_dw";
export var badge = "q_rd";
export var padding = "q_rf g_fR";
export var end = "q_rg g_dp";
export var linkWrapper = "q_rh g_gj";
export var link = "q_bj g_gj";
export var colWrapper = "q_rj g_ff";
export var media = "q_rk g_df g_gg";
export var itemRight = "q_rl";
export var itemLeft = "q_rm";
export var itemCenter = "q_rn";
export var exceptionWeight = "q_rp x_tc";