// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "d_5";
export var warningBackgrounddark = "d_6";
export var warningBackgroundcustom = "d_7";
export var bottom = "d_8";
export var modal = "d_9";
export var container = "d_bb";
export var btnWrapper = "d_bc";
export var elementWrapper = "d_bd";
export var titleWrapper = "d_bf";
export var contentWrapper = "d_bg";
export var btn = "d_bh";
export var link = "d_bj";
export var decline = "d_bk d_bh";
export var editor = "d_bl";
export var row = "d_bm";