// extracted by mini-css-extract-plugin
export var carouselContainer = "F_vG g_ch g_cs g_h g_cL";
export var carouselContainerCards = "F_vH F_vG g_ch g_cs g_h g_cL";
export var carouselContainerSides = "F_vJ g_ch g_cs g_cL";
export var prevCarouselItem = "F_vK g_ch g_cs g_cM g_b5";
export var prevCarouselItemL = "F_vL F_vK g_ch g_cs g_cM g_b5";
export var moveInFromLeft = "F_vM";
export var prevCarouselItemR = "F_vN F_vK g_ch g_cs g_cM g_b5";
export var moveInFromRight = "F_vP";
export var selectedCarouselItem = "F_vQ g_ch g_cs g_cL g_h";
export var selectedCarouselItemL = "F_vR F_vQ g_ch g_cs g_cL g_h";
export var selectedCarouselItemR = "F_vS F_vQ g_ch g_cs g_cL g_h";
export var nextCarouselItem = "F_vT g_ch g_cs g_cM g_b5";
export var nextCarouselItemL = "F_vV F_vT g_ch g_cs g_cM g_b5";
export var nextCarouselItemR = "F_vW F_vT g_ch g_cs g_cM g_b5";
export var arrowContainer = "F_vX g_cs g_cM g_gj g_c3 g_cR g_dK";
export var prevArrowContainer = "F_vY F_vX g_cs g_cM g_gj g_c3 g_cR g_dK";
export var prevArrowContainerHidden = "F_vZ F_vY F_vX g_cs g_cM g_gj g_c3 g_cR g_dK g_b5";
export var nextArrowContainer = "F_v0 F_vX g_cs g_cM g_gj g_c3 g_cR g_dK";
export var carouselContainerProducts = "F_v1";
export var nextArrowContainerHidden = "F_v2 F_v0 F_vX g_cs g_cM g_gj g_c3 g_cR g_dK g_b5";
export var arrow = "F_l7 g_cM";
export var prevArrow = "F_v3 F_l7 g_cM";
export var nextArrow = "F_v4 F_l7 g_cM";
export var carouselIndicatorContainer = "F_v5 g_ch g_gd g_c3 g_dh g_dl g_dw g_dq g_cM";
export var btnsContainer = "F_v6 g_ch g_dh g_dm";
export var carouselText = "F_v7 g_f7 g_dh g_dl g_dw g_dK g_gj g_fl";
export var carouselTextInactive = "F_v8 F_v7 g_f7 g_dh g_dl g_dw g_dK g_gj g_fl";
export var indicator = "F_v9 g_dR";
export var carouselIndicator = "F_wb F_v9 g_dR";
export var carouselIndicatorSelected = "F_wc F_v9 g_dR";
export var arrowsContainerTopRight = "F_wd g_cM g_c3 g_dk";
export var arrowsContainerBottomLeft = "F_wf g_cM g_c3 g_dk";
export var arrowsContainerSides = "F_wg g_cM g_c3 g_dk";
export var smallArrowsBase = "F_wh g_dB g_dh g_dl g_dw g_dK g_gj g_fl";
export var smallArrowContainer = "F_wj F_wh g_dB g_dh g_dl g_dw g_dK g_gj g_fl";
export var smallArrowContainerPrev = "F_wk F_wj F_wh g_dB g_dh g_dl g_dw g_dK g_gj g_fl";
export var smallArrowContainerSmall = "F_wl F_wh g_dB g_dh g_dl g_dw g_dK g_gj g_fl";
export var smallArrowContainerPrevSmall = "F_wm F_wl F_wh g_dB g_dh g_dl g_dw g_dK g_gj g_fl";
export var icon = "F_b0";
export var iconSmall = "F_wn";
export var multipleWrapper = "F_wp g_dk g_dm g_h";
export var multipleImage = "F_wq g_dk";
export var sidesPrevContainer = "F_wr F_wj F_wh g_dB g_dh g_dl g_dw g_dK g_gj g_fl g_gZ g_cM g_c3 g_fl";
export var sidesNextContainer = "F_ws F_wj F_wh g_dB g_dh g_dl g_dw g_dK g_gj g_fl g_gZ g_cM g_c3 g_fl";