// extracted by mini-css-extract-plugin
export var socialContainer = "y_tV";
export var socialContainerFull = "y_tW";
export var instagramLink = "y_tX";
export var socialWrapperLeft = "y_n3 g_gk g_cL g_dh g_dq g_c6 g_dn";
export var socialContentContainer = "y_tY";
export var socialContentContainerFull = "y_tZ";
export var instagramContainer = "y_t0";
export var twitterContainer = "y_t1";
export var facebookContainer = "y_t2";
export var socialErrorContainer = "y_t3";
export var facebookContainerWide = "y_t4";
export var twitterContainerWide = "y_t5";
export var socialParagraphCenter = "y_t6";
export var instaWrapper = "y_t7";
export var SubtitleSmall = "y_b2 x_b2 x_sM x_sY";
export var SubtitleNormal = "y_b3 x_b3 x_sM x_sZ";
export var SubtitleLarge = "y_b4 x_b4 x_sM x_s0";